<template>
  <v-main>
    <AppBar :buttons="buttons" title="Profile" @onSave="onSave"></AppBar>
    <Loading v-model="loading" />
    <v-container>

      <v-row>
        <v-col>
          <v-alert v-if="success" type="success" dismissible>{{success}}</v-alert>
          <v-alert v-if="error" type="error" dismissible>{{error}}</v-alert>
        </v-col>
      </v-row>

      <v-form v-model="valid">
      <v-row>
        <v-col cols="12" sm="12">
          <v-text-field type="password" v-model="currentPassword" :rules="rules.currentPassword" label="Current Password"></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12">
          <v-text-field type="password" v-model="newPassword" :rules="rules.newPassword" label="New Password"></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12">
          <v-text-field type="password" v-model="verifyPassword" :rules="rules.verifyPassword" label="Verify New Password"></v-text-field>
        </v-col>
      </v-row>
      </v-form>
    </v-container>
  </v-main>
</template>
<script>
import AppBar from '../components/general/AppBar.vue'
import Loading from '@/components/Loading.vue'
export default {
  name: 'Profile',
  components: {
    AppBar,
    Loading
  },
  data: () => ({
    valid: false,
    currentPassword: null,
    newPassword: null,
    verifyPassword: null,
    loading: false,
    error: null,
    success: null, 
  }),
  computed: {
    buttons() {
      return [
        {label: 'Save', click: 'onSave', disabled: !this.valid, color: 'primary'}
      ]
    },
    rules() {
      return {
        currentPassword: [
          v => !!v || 'required'
        ],
        newPassword: [
          v => !!v || 'required'
        ],
        verifyPassword: [
          v => !!v || 'required',
          v => v === this.newPassword || 'password doesn\'t match'
        ],
      }
    }
  },
  methods: {
    onSave() {
      this.loading = true
      this.success = null
      this.error = null
      this.$api.org.updatePassword(this.currentPassword, this.newPassword)
        .then((data) => {
          this.success = data
        })
        .catch((data) => {
          this.error = data.message
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>